/* eslint-disable @typescript-eslint/no-var-requires */
import type { App } from '@vue/runtime-core'
import type { i18n } from 'i18next'

import {createRouter, createWebHistory} from 'vue-router'
import { RouteNames } from '@/route-names'
import { routes } from './root-route'

export default function install(app: App, pluginOptions: { i18n: i18n }) {
  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
      ...routes,
      ...(window.routes ?? []).flat()
    ],
  })

  router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth) && !(await window.auth.isAuthenticated())) {
      await window.auth.login(to.fullPath)
    } else if (to.meta.allowInProd === false && window.env['WEBSITE_ENV'] === 'PROD') {
      await router.push({
        name: RouteNames.ProfileRequestAccess,
        query: {roles: `${to.meta.roles}`}
      })
    } else if (!to.meta.roles || await window.auth.hasRoles(to.meta.roles)) {
      if (to.name && typeof to.name === 'string') {
        await pluginOptions.i18n.loadNamespaces(to.name)
        if (to.meta.additionalTranslations) {
          await pluginOptions.i18n.loadNamespaces(to.meta.additionalTranslations)
        }
      }
      next()
    } else {
      await router.push({
        name: RouteNames.ProfileRequestAccess,
        query: {roles: `${to.meta.roles}`}
      })
    }
  })

  app.use(router)
}
