<template>
  <el-menu
    :collapse="isCollapsed"
    :default-active="activeItem"
    :unique-opened="true"
    router
  >
    <el-menu-item key="logo" index="/" class="el-header-item">
      <img src="../assets/logo-white.svg" alt="Engie" class="main-logo">
    </el-menu-item>
    <template v-for="item of visibleRoutes">
      <el-sub-menu
        v-if="item.children.length > 0"
        :index="String(item.route)"
        :key="item.route"
      >
        <template #title>
          <div class="el-icon" v-if="item.icon">
            <font-awesome-icon :icon="item.icon" />
          </div>
          <span>{{ item.label }}</span>
        </template>
        <template v-for="child of item.children" :key="child.route">
          <el-menu-item
            :index="String(child.route)"
            :route="{ name: child.route }"
          >
            <template #title>
              <div class="el-icon" v-if="child.icon">
                <font-awesome-icon :icon="child.icon" />
              </div>
              <span>{{ child.label }}</span>
            </template>
            <router-link :to="{ name: child.route }" />
          </el-menu-item>
        </template>
      </el-sub-menu>
      <el-menu-item
        v-else-if="item.standAlone"
        :index="String(item.route)"
        :route="{ name: item.route }"
        :key="`${String(item.route)}-else`"
      >
        <div class="el-icon" v-if="item.icon">
          <font-awesome-icon :icon="item.icon" />
        </div>
        <template #title>
          <span>{{ item.label }}</span>
        </template>
        <router-link :to="{ name: item.route }" />
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator'
import type { RouteRecordName, RouteRecordRaw } from 'vue-router'
import { hasOneOfRoles, env } from '@service-portal/commons'

interface MenuRoute {
  label: string
  standAlone?: boolean
  icon?: string
  route: RouteRecordName
  children: MenuRoute[]
}

@Component({ })
export default class MainMenu extends Vue {
  @Prop({ default: false, type: Boolean })
  readonly isCollapsed!: boolean

  userRoles: string[] = []

  async mounted () {
    this.userRoles = await window.auth.getUserRoles()
  }

  get activeItem () {
    return String((this.$route.meta?.showInMenu ? this.$route.name : this.$route.meta?.menuItem) || '')
  }

  get visibleRoutes () {
    return this.mapRoutes(this.$router.options.routes)
  }

  mapRoutes (routes: readonly RouteRecordRaw[]): MenuRoute[] {
    return routes
      .filter(route => route.meta && route.meta.showInMenu)
      .filter(route => route.meta && route.meta.allowInProd !== false || env.get('WEBSITE_ENV') !== 'PROD')
      .filter(route => hasOneOfRoles(this.userRoles, route.meta?.roles || []))
      /* eslint-disable @typescript-eslint/indent */
      .map<MenuRoute>(route => ({
        label: route.meta?.title ?? '',
        route: route.name || '',
        icon: route.meta?.icon,
        standAlone: Boolean(!route.children || route.meta?.standAlone),
        children: route.children
          ? this.mapRoutes(route.children)
          : []
      }))
  }
}
</script>
