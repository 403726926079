/* eslint-disable camelcase */

import install from './install'
import type { InitOptions, BackendModule, LanguageDetectorModule } from 'i18next';
import i18next from 'i18next'

export const DEFAULT_NAMESPACE = 'default'
export const I18N_LANGUAGE_KEY = 'language'

function detectUserLanguage () {
  const browserLang = (navigator.language || 'EN')
    .replace(/^([a-zA-Z]{2})(-.+)?/g, '$1')
    .toUpperCase()
  if (browserLang === 'EN' || browserLang === 'FR' || browserLang === 'NL') {
    return browserLang
  }
  return 'EN'
}

async function init (options: InitOptions = {}) {
  const instance = i18next
    .createInstance()
    .use({
      type: 'languageDetector',
      detect() {
        try {
          const item = window.localStorage.getItem(I18N_LANGUAGE_KEY)
          if (item) {
            return JSON.parse(item)
          }
        } catch {}
        return detectUserLanguage()
      },
      cacheUserLanguage(language: string) {
        console.log(`languageDetector: save ${language}`)
        window.localStorage.setItem(I18N_LANGUAGE_KEY, JSON.stringify(language))
      }
    } as LanguageDetectorModule)
    .use({
      type: 'backend',
      init (_services, _backendOptions, _i18nextOptions) { /* use services and options */ },
      read (language, namespace, callback) {
        if (!window.locales) {
          callback(`locales not found`, null)
          return
        }

        const toLoad = `${language.toLowerCase()}:${namespace}`

        if (!(toLoad in window.locales)) {
          callback(`locales(${toLoad}) not found`, null)
          return
        }

        window.locales[toLoad]()
          .then((resources) => {
            callback(null, resources.default as any)
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.info(`loading locales for '${toLoad}' aborted`)
            callback(error, null)
          })
      }
    } satisfies BackendModule)

  await instance.init({
    debug: false,
    defaultNS: DEFAULT_NAMESPACE,
    ns: ['common', 'menu'],
    fallbackNS: '',
    fallbackLng: false,
    interpolation: {
      escapeValue: false,
      skipOnVariables: false
    },
    ...options,
  } satisfies InitOptions<any>)

  return instance
}

export {
  init,
  install
}
