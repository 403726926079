/// <reference types="vite/client" />
/// <reference types="element-plus/global" />
/// <reference types="@service-portal/commons/types" />
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import './assets/style/index.scss'

import ElementPlus from 'element-plus'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ELLink from 'components/ELLink.vue'
import { Auth } from '@service-portal/commons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { install as VueSSE } from 'vue-sse'

import hasRole from 'directives/hasRole'
import clipboard from 'directives/clipboard'

import * as VueI18n from './i18n/index'

import * as icons from './icons'

window.auth ??= new Auth()

window.locales ??= {}
window.locales['en:menu'] = () => import(`./locales/en/menu.json`)
window.locales['fr:menu'] = () => import(`./locales/fr/menu.json`)
window.locales['nl:menu'] = () => import(`./locales/nl/menu.json`)

window.locales['en:common'] = () => import(`./locales/en/common.json`)
window.locales['fr:common'] = () => import(`./locales/fr/common.json`)
window.locales['nl:common'] = () => import(`./locales/nl/common.json`)

async function init () {
  const i18nInstance = await VueI18n.init()

  library.add(...Object.values(icons))

  const app = createApp(App)

  app.use(router, {
    i18n: i18nInstance
  })
  app.use(ElementPlus)
  app.use(hasRole)
  app.use(clipboard)
  app.use(VueI18n.install, {
    i18n: i18nInstance
  })

  // Use VueSSE, including a polyfill for older browsers
  app.use(VueSSE, {
    polyfill: true
  })

  app.component('font-awesome-icon', FontAwesomeIcon)
  app.component('el-link', ELLink)

  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(`ElIcon${key}`, component)
  }

  app.mount('#app')
}

init()
