<template>
  <el-container v-if="userInfo">
    <el-aside>
      <main-menu :is-collapsed="isCollapsed" />
    </el-aside>
    <el-container>
      <el-header class="header">
        <el-row
          style="width: 100%"
          type="flex"
          justify="space-between"
          align="middle"
        >
          <div class="el-col">
            <el-row type="flex" align="middle">
              <el-button
                @click="isCollapsed = !isCollapsed"
                class="el-button--collapse"
              >
                <font-awesome-icon :icon="isCollapsed ? 'chevron-right' : 'chevron-left'" />
              </el-button>
              <breadcrumb />
            </el-row>
          </div>
          <div class="el-col">
            <el-row
              type="flex"
              justify="end"
              align="middle"
            >
              <el-popover
                v-if="envName"
                placement="bottom"
                width="380"
                trigger="hover"
                content="this is content, this is content, this is content"
              >
                <template #reference>
                  <el-tag
                    type="info"
                    effect="dark"
                    size="small"
                    class="cursor-pointer"
                  >
                    {{ envName }}
                  </el-tag>
                </template>
                <el-row>
                  <el-descriptions
                    title="Container"
                    size="small"
                    direction="horizontal"
                    border
                  >
                    <el-descriptions-item :label="$t('menu:version')">
                      <el-tag type="info">{{ buildID }}</el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item :label="$t('menu:buildDate')">
                      <el-tag type="info">{{ buildDate }}</el-tag>
                    </el-descriptions-item>
                  </el-descriptions>
                </el-row>
                <template v-if="appModuleBuildID && appModuleBuildDate">
                  <el-divider />
                  <el-row>
                    <el-descriptions
                      title="Current App"
                      size="small"
                      direction="horizontal"
                      border
                    >
                      <el-descriptions-item :label="$t('menu:version')">
                        <el-tag type="info">{{ appModuleBuildID }}</el-tag>
                      </el-descriptions-item>
                      <el-descriptions-item :label="$t('menu:buildDate')">
                        <el-tag type="info">{{ appModuleBuildDate }}</el-tag>
                      </el-descriptions-item>
                    </el-descriptions>
                  </el-row>
                </template>
              </el-popover>
              <UseDark v-slot="{ isDark, toggleDark }">
                <el-switch
                  style="padding-left: 20px"
                  :model-value="!isDark"
                  @change="toggleDark()"
                  inline-prompt
                  active-icon="el-icon-sunny"
                  inactive-icon="el-icon-moon"
                />
              </UseDark>
              <el-dropdown
                style="padding-left: 20px"
                trigger="click"
                @command="handleLanguageCommand"
              >
                <span class="cursor-pointer">
                  {{ language }}
                  <el-icon class="el-icon--right">
                    <el-icon-arrow-down />
                  </el-icon>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      v-for="lang of languageOptions"
                      :key="lang"
                      :command="lang"
                    >
                      {{ lang }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
              <el-dropdown
                v-if="userInfo"
                trigger="click"
                @command="handleCommand"
              >
                <span class="cursor-pointer">
                  <font-awesome-icon icon="user"/>
                  <span style="padding-left:5px; font-size: 14px">{{ userInfo.profile.name }}</span>
                  <el-icon class="el-icon--right">
                    <el-icon-arrow-down />
                  </el-icon>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item v-if="canRequestAccess" command="request">{{ $t('menu:action.request') }}</el-dropdown-item>
                    <el-dropdown-item command="reload">{{ $t('menu:action.reload') }}</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </el-row>
          </div>
        </el-row>
      </el-header>
      <el-container>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </el-container>
  <div v-else></div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator'
import { format } from 'date-fns'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { UseDark } from '@vueuse/components'

import type { User as LoggedInUser } from 'oidc-client-ts'
import { Language } from '@service-portal/commons'
import { RouteNames } from '@/route-names'

import Breadcrumb from 'components/Breadcrumb.vue'
import MainMenu from 'components/MainMenu.vue'

@Component({
  components: { FontAwesomeIcon, UseDark, Breadcrumb, MainMenu }
})
export default class App extends Vue {
  userInfo: LoggedInUser | null = null
  isCollapsed: boolean = false
  canRequestAccess = import.meta.env.VITE_MODE !== 'public'

  appModuleBuildID: string | null = null
  appModuleBuildDate: string | null = null

  @Watch('$route')
  async onRouteChange () {
    await this.init()
  }

  get buildDate () {
    return import.meta.env.buildDate
  }

  get buildID () {
    return import.meta.env.buildID
  }

  get envName () {
    return window.env.WEBSITE_ENV
  }

  async init () {
    const appBuildInfo = this.$route.meta.buildInfo || null
    if (appBuildInfo) {
      this.appModuleBuildID = appBuildInfo.buildId
      this.appModuleBuildDate = appBuildInfo.buildDate
    }

    const user = await window.auth.getUser()
    if (user) {
      this.userInfo = user
    } else {
      await window.auth.login()
    }
  }

  get languageOptions () {
    return Object.values(Language)
  }

  get language () {
    return this.getLanguage
  }

  async mounted () {
    await this.init()
  }

  handleLanguageCommand (command: Language) {
    this.$t_setLanguage(command)
  }

  handleCommand (command: 'reload' | 'request') {
    switch (command) {
      case 'reload':
        window.localStorage.clear()
        window.sessionStorage.clear()
        window.auth.logout()
        break
      case 'request':
        this.$router.push({
          name: RouteNames.ProfileRequestAccess
        })
        break
    }
  }
}
</script>
