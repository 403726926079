import type { App } from 'vue'
import type { i18n, TOptions } from 'i18next'

function debugKey (key: string | string[], options: any = null) {
  const keyText = key instanceof Array ? key.join(' | ') : key
  let optionsText = ''
  // options are only displayed for keys that are not related to validation (there, options are well known)
  if (options && keyText.indexOf('.validation.') === -1) {
    // in debug mode interpolation config pollutes the output
    options.interpolation = undefined
    optionsText = options ? ' * ' + JSON.stringify(options) : ''
  }
  return `${keyText}${optionsText}`
}

interface PluginOptions {
  missingKeyHandler?: (lng: string, ns: string | false | readonly string[] | undefined, key: string, key2: string) => void
  i18n: i18n
}

type TranslationMapping = Readonly<Record<number | string, string | null>>

declare module '@vue/runtime-core' {
  interface Vue {
    readonly $i18n: i18n
  }
}

export default function install (Vue: App, pluginOptions: PluginOptions): void {
  Vue.mixin({
    data () {
      return {
        $t_debugEnabled: /* pluginOptions.i18n ? Boolean(pluginOptions.i18n.debug) : */ false,
        getLanguage: pluginOptions.i18n ? pluginOptions.i18n.language : false
      }
    },
    computed: {
      $i18n () {
        return pluginOptions.i18n
      },

      $t (): (this: App, key: string | string[], options?: TOptions) => string {
        if (!this.$data.$t_debugEnabled) {
          return function (key, options): string {
            if (!key) return 'undefined'

            if (pluginOptions.i18n.exists(key)) {
              return pluginOptions.i18n.t(key, options)
            }

            pluginOptions.missingKeyHandler?.(pluginOptions.i18n.language, pluginOptions.i18n.options.defaultNS, debugKey(key), debugKey(key, options))
            return key instanceof Array ? key[0] : key
          }
        } else {
          return function (key, options) {
            return debugKey(key, options)
          }
        }
      },

      $t_exists () {
        if (!this.$data.$t_debugEnabled) {
          return function (this: App, key: any, options: any) {
            if (!key) return false
            return pluginOptions.i18n.exists(key, options)
          }
        } else {
          return function () {
            return true
          }
        }
      }
    },

    created () {
      this.$data.$t_debugEnabled = !!pluginOptions.i18n.options.debug
      this.getLanguage = pluginOptions.i18n.language
      pluginOptions.i18n.on('languageChanged', this.$__onLanguageChange)
    },

    beforeUnmount() {
      pluginOptions.i18n.off('languageChanged', this.$__onLanguageChange)
    },

    methods: {
      $__onLanguageChange (lng: string): void {
        this.getLanguage = lng
      },

      async $t_setLanguage (lang: string) {
        await pluginOptions.i18n.changeLanguage(lang)
      },

      $t_list (key: string, options?: TOptions) {
        const ret: string[] = []
        let index = 0
        while (pluginOptions.i18n.exists(`${key}.${index}`)) {
          ret.push(this.$t(`${key}.${index++}`, options))
        }
        return ret
      },

      $t_map (mapping: TranslationMapping, options?: TOptions) {
        const collect = (from = 0, into: TranslationMapping[] = []): TranslationMapping[] => {
          const collection = { ...mapping }
          let stop = true

          for (const [key, value] of Object.entries(mapping)) {
            const exists = pluginOptions.i18n.exists(`${value}.${from}`)
            // @ts-ignore
            collection[key] = exists ? this.$t(`${value}.${from}`, options) : null
            stop = stop && !exists
          }

          return stop ? into : collect(from + 1, into.concat([collection]))
        }

        return collect()
      },

      $t_bool (key: string | string[], options?: TOptions) {
        return !pluginOptions.i18n.t(key, options)
          .trim()
          .match(/^(?:false|no)$/gi)
      },

      $t_dropdown (enumClass: any, prefixes: string | string[], filtered: any = []) {
        if (typeof prefixes === 'string') {
          prefixes = [prefixes]
        }
        const translatedEnumValues: { key: string, value: any, text: string }[] = []

        const enumNames = Object.values<string>(enumClass)
          .filter((name) => filtered.indexOf(name) === -1)

        for (const elm of enumNames) {
          const keys: string[] = []
          for (const prefix of prefixes) {
            keys.push(`${prefix}.${elm}`)
          }
          translatedEnumValues.push({
            key: elm,
            value: elm,
            // @ts-ignore
            label: this.$t(keys)
          })
        }
        return translatedEnumValues
      }
    }
  })
}
